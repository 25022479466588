import type { ComponentType } from "react"
import { useEffect, useState } from "react"
import { createStore } from "https://framer.com/m/framer/store.js@^1.0.0"
import { randomColor } from "https://framer.com/m/framer/utils.js@^0.9.0"
import {
    useAccount,
    useNetwork,
    usePrepareContractWrite,
    useContractWrite,
    useContractReads,
} from "wagmi"
import { parseEther } from "viem"
import { mfersMatserContract } from "./Abis.tsx"

const useStore = createStore({
    isLoading: true,
    error: null,
})

const chainNames = {
    1: "mainnet",
    11155111: "sepolia",
    5: "goerli",
}

export function withDonateHome(Component): ComponentType {
    return (props) => {
        const [store, setStore] = useStore()
        const { address } = useAccount()
        const { chain } = useNetwork()
        const [ownedNfts, setOwnedNfts] = useState([])

        useEffect(() => {
            if (!chain) {
                return
            }
            let chainName = chainNames[chain.id]
            setStore({ ...store, isLoading: true })
            fetch(
                `https://api.mferscoin.com/mfers/check?address=${address}&chain=${chainName}`
            )
                .then((res) => res.json())
                .then((data) => {
                    setOwnedNfts(
                        data.ownedNfts.map((nft) =>
                            parseInt(nft.id.tokenId, 16)
                        )
                    )
                })
                .catch((err) => {
                    setStore({ ...store, isLoading: false, error: err.message })
                    console.log(err.message)
                })
        }, [])

        const { data, error, isError, status } = useContractReads({
            contracts: ownedNfts.map((nftId) => ({
                ...mfersMatserContract,
                functionName: "nftContributed",
                args: [nftId],
            })),
        })

        useEffect(() => {
            if (data == null || ownedNfts.length != data.length) {
                return
            }
            const availableNfts = ownedNfts
                .map((value, index) => (data[index].result ? null : value))
                .filter((value) => value !== null)
            let nftData = {
                ownedNfts: ownedNfts,
                availableNfts: availableNfts,
            }
            setStore({
                ...store,
                nftData: nftData,
                isLoading: false,
            })
        }, [ownedNfts])

        return <Component {...props} />
    }
}

export function withLoadingFrame(Component): ComponentType {
    return (props) => {
        const [store] = useStore()
        const { isLoading } = store
        return isLoading && <Component {...props} />
    }
}

export function withErrorFrame(Component): ComponentType {
    return (props) => {
        const [store] = useStore()
        const { error } = store
        return error && <Component {...props} />
    }
}

export function withErrorText(Component): ComponentType {
    return (props) => {
        const [store] = useStore()
        const { error } = store
        return <Component {...props} text={error} />
    }
}

export function withDonateText(Component): ComponentType {
    return (props) => {
        const [store] = useStore()
        const { nftData } = store
        let text = "Donate"
        if (nftData && nftData.availableNfts) {
            text = `Donate my ${nftData.availableNfts.length} mfers`
        }
        return <Component {...props} text={text} />
    }
}

export function withDonateButton(Component): ComponentType {
    return (props) => {
        const [store, setStore] = useStore()
        const { nftData } = store
        let nftCount = 0
        let nfts = []
        if (nftData && nftData.availableNfts) {
            nftCount = nftData.availableNfts.length
            nfts = nftData.availableNfts
        }

        let etherCount = 0.1 * nftCount
        const { config, error, isError } = usePrepareContractWrite({
            ...mfersMatserContract,
            functionName: "contribute",
            args: [nfts],
            value: parseEther(etherCount.toFixed(1)),
        })
        const { data, write } = useContractWrite(config)
        console.log("123")
        console.log(error)
        useEffect(() => {
            if (error) {
                setStore({ ...store, error: error })
            }
        }, [])
        return (
            nftCount > 0 && (
                <Component
                    {...props}
                    disabled={!write}
                    onClick={() => {
                        write?.()
                    }}
                />
            )
        )
    }
}
